@import url(https://fonts.googleapis.com/css2?family=Averia+Serif+Libre&family=Suravaram&family=IM+Fell+Double+Pica&family=IBM+Plex+Sans&family=Patua+One&family=Sunflower:wght@500&display=swap);
html {
  background: black;
}

body {
  background: black;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

